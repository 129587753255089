import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { Remote } from '~/services/Remote'

import './index.css'


@Component()
export
  class RevenueIndexView extends ReactiveComponent {

  date = JSON.parse(localStorage.getItem('store_related'))?.date || this.defaultDate() // /时间选择器 读本地缓存里store_related  1.有：读 store_related.date 2.无：读 默认选择当前时间
  showTimes = false //时间选择器日历 显示/隐藏

  sumAmount = '' //分成总额
  revenueList = [] //分成门店数据
  loading = false //加载效果

  // 默认选中当前时间
  defaultDate() {
    const currentDate = new Date();

    // 获取年份、月份和日期
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    // 格式化为 yy-mm-dd 格式
    return formattedDate = `${year}-${month}-${day}`;
  }

  created() {
    this.getRevenueStoreData()
  }

  @Watch('date')
  loadRevenuedData() {
    this.getRevenueStoreData()
  }

  //获取列表数据
  async getRevenueStoreData() {
    this.loading = true
    let res
    try {
      res = await Remote.get().http('/api/revenue/dateAmount', {
        method: 'POST',
        body: {
          date: this.date,
        }
      })
    } catch (error) {
      this.$notify({ type: 'warning', message: error.message })

      return
    }

    this.revenueList = res.reports
    this.sumAmount = res.sum_amount
    this.loading = false

  }

  // 日期选择后触发Confirm
  onConfirm(date) {
    this.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    this.showTimes = false
  }

  // 跳转门店分成
  navigate(item) {
    const store_related = {
      store_id: item.store_id,
      store_name: item.store_name,
      date: this.date
    }

    localStorage.setItem('store_related', JSON.stringify(store_related))

    this.$router.push({
      name: 'device-revenue-list'
    })
  }

  render() {
    return (
      <div class='revenue-index'>
        <div class="header">
          <div class="header-content">
            <span class="span">分成总额</span>
            <span class="span money">{this.sumAmount}元</span>
          </div>
        </div>

        <div class='seach-time'>
          <van-cell title="扫码收益时间" value={this.date} onClick={() => this.showTimes = true} />
          <van-calendar
           value={this.showTimes}
           onInput={() => this.showTimes = false}
           onConfirm={(date) => this.onConfirm(date)}
           min-date={new Date(2022, 1, 1)}
           max-date={new Date()}
           default-date={new Date(this.date)}
           />
        </div>

        <div class='content' v-loading={this.loading}>
          {
            this.revenueList.length === 0 &&
            <van-empty description="暂无数据" />
          }
          {
            this.revenueList.length !== 0 &&
            this.revenueList.map((item, index) => (
              <ul class='list-box'>
                <div class='revenue-amount' onClick={() => this.navigate(item)}>
                  <li style="font-weight: 600">
                    <span>{item.store_name}</span>
                    <span>营收</span>
                    <span>分成</span>
                  </li>
                  <li style="border-bottom: 1px solid #cccccc;">
                    <span>总额</span>
                    <span>{item.store_revenue.amount}</span>
                    <span>{item.amount}</span>
                  </li>
                </div>
                <div class='device-box'>
                  <li>
                    <span>C60按摩椅</span>
                    <span>{item.store_revenue.chair_amount}</span>
                    <span>{item.chair_amount}</span>
                  </li>
                  <li>
                    <span>氢氧机</span>
                    <span>{item.store_revenue.hydrogen_amount}</span>
                    <span>{item.hydrogen_amount}</span>
                  </li>
                  <li>
                    <span>氢水机</span>
                    <span>{item.store_revenue.hydrogen_water_amount}</span>
                    <span>{item.hydrogen_water_amount}</span>
                  </li>
                </div>
              </ul>
            ))
          }
        </div>
      </div>
    )
  }
}
