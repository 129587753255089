import { AccountViewProvider } from '~/pages/user/account'
import { UserIncomeViewProvider } from '~/pages/user/income'
import { UserWithdrawRecordViewProvider } from '~/pages/user/withdraw'
import { UserWithdrawToWeixinView } from '~/pages/user/withdraw-weixin'

import { DeviceBindingViewProvider } from './hydrogen/DeviceBinding'

import { PatrolApplicationViewProvider } from './hydrogen/PatrolApplication'

import { DeviceDetectionViewProvider } from '~/pages/detection/deviceDetection'

import { RevenueBindView } from '~/pages/revenue/bind'
import { RevenueIndexView } from "~/pages/revenue/index"
import { DeviceRevenueListView } from "~/pages/revenue/device-revenue-list";

export
const routes = [
  {
    path: '/',

    children: [
      {
        name: 'my',
        provider: AccountViewProvider,
      },

      {
        name: 'income',
        provider: UserIncomeViewProvider,
      },

      {
        name: 'withdraw',
        provider: UserWithdrawRecordViewProvider,
      },

      {
        name: 'withdraw-weixin',
        path: '/withdraw-wx',
        component: UserWithdrawToWeixinView,
        meta: { title: '提现' },
      },

      {
        name: 'deviceBinding',
        provider: DeviceBindingViewProvider,
      },

      {
        name: 'patrolApplication',
        provider: PatrolApplicationViewProvider,
      },

      {
        name:'deviceDetection',
        provider: DeviceDetectionViewProvider
      },

      {
        name: 'revenue-bind',
        path: 'revenueBind',
        component: RevenueBindView,
        meta: { title: '分成绑定' },
      },

      {
        name:'revenue-index',
        path:'revenueIndex',
        component: RevenueIndexView,
        meta: { title: '分成详情' },
      },

      {
        name:'device-revenue-list',
        path:'revenueList',
        component:DeviceRevenueListView,
      }
    ]
  }
]
