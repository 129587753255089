import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '~/services/Remote'

import './income.css'

export
function UserIncomeViewProvider(BaseComponent) {
  @Component()
  class UserIncomeView extends BaseComponent {
    userBalance = 0

    mounted() {
      this.loadUserBalance()
    }

    async loadUserBalance() {
      try {
        const { balance } = await Remote.get().http('/api/check/user_blance')

        this.userBalance = balance
      } catch (err) {

      }
    }

    /** @override */
    async getIncome() {
      let data

      try {
        data = await Remote.get().http('/api/check/user_commsions_record')
      } catch (err) {
        return
      }

      this.data.accumulative = data.commissions?.amount ?? 0
      this.data.balances = data.commissions_wait?.amount ?? 0
      this.data.data ??= []

      for (const month of Object.keys(data.list)) {
        this.data.data.push({
          date_name: month,

          earnings: data.list[month].map(item => {
            item.earnings_name = item.earnings_type

            return item
          })
        })
      }
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: sectionHeader } = root.query({ as: <div class="top" /> }).next()

      sectionHeader?.classList?.add('header')
      sectionHeader?.children?.unshift(
        <p class="balance-title text1">我的余额(元)</p>
        ,

        <p class="balance-value allMoney">{ Number(this.userBalance ?? 0).toFixed(2) }</p>
        ,

        <div class="cell">
          <router-link class="btn" to={ { name: 'withdraw' } }>提现记录</router-link>
          <router-link class="btn" to={ { name: 'withdraw-weixin' } }>提现到微信</router-link>
        </div>
        ,
      )

      return vnode
    }
  }

  return UserIncomeView
}
