import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import './account.css'

export
function AccountViewProvider(BaseComponent) {
  @Component()
  class AccountView extends BaseComponent {
    user = {
      shop_user_relevance: {},
    }

    get orderItems() {
      return [
        {
          icon: 'icon-pay',
          title: '待付款',
          badge: this.user.wait_pay,
          route: [ 'order', 'NOTPAY' ],
        },
        {
          icon: 'icon-deliver',
          title: '待发货',
          badge: this.user.wait_send,
          route: [ 'order', 'WAITSEND' ],
        },
        {
          icon: 'icon-receive',
          title: '待收货',
          badge: this.user.wait_get,
          route: [ 'order', 'WATTTAKE' ],
        },
        {
          icon: 'icon-remark',
          title: '待评价',
          badge: this.user.wait_estimate,
          route: null,
        },
        {
          icon: 'icon-post-sale',
          title: '售后',
          badge: this.user.after_sale,
          route: 'afterSellOrder',
        },
      ]
    }

    get serviceItems() {
      const { user } = this

      const showButtonPartner = (
        user.shop_user_relevance && (
          user.shop_user_relevance.type == 88
        )
      )

      const showButtonBroker = (
        user.shop_user_relevance &&
          (
            user.shop_user_relevance.type == 88 ||
            (
              user.shop_user_relevance.type == 1 &&
              user.shop_user_relevance.audit_status == 1
            )
          )
      )

      const showButtonFans = (
        user.shop_user_relevance && (
          user.shop_user_relevance.type == 2 ||
          user.shop_user_relevance.type == 88 ||
          (
            user.shop_user_relevance.type == 1 &&
            user.shop_user_relevance.audit_status == 1
          )
        )
      )

      const showButtonBalance = (
        user.shop_user_relevance &&
          (
            user.shop_user_relevance.type == 2 ||
            user.shop_user_relevance.type == 88 ||
            (
              user.shop_user_relevance.type == 1 &&
              user.shop_user_relevance.audit_status == 1
            )
          )
      )

      return [
        {
          icon: 'icon-hcard',
          title: '我的氢卡',
          route: 'myCard',
        },
        {
          icon: 'icon-coupon',
          title: '我的卡券',
          route: 'coupon',
        },

        {
          icon: 'icon-favor',
          title: '我的收藏',
          route: null,
        },

        {
          icon: 'icon-address',
          title: '地址管理',
          route: 'addressList',
        },

        showButtonPartner &&
        {
          icon: 'icon-partner',
          title: '邀请股东',
          route: [ 'inviteFriend', 2, this.user.shop_user_relevance.affiliation ],
        },

        showButtonBroker &&
        {
          icon: 'icon-broker',
          title: '邀请加盟',
          route: [ 'inviteFriend', 3, this.user.shop_user_relevance.affiliation ],
        },

        showButtonFans &&
        {
          icon: 'icon-fans',
          title: '我的粉丝',
          route: 'fans',
        },

        showButtonBalance &&
        {
          icon: 'icon-balance',
          title: '我的余额',
          route: 'income',
        },

        {
          icon: 'icon-instalment',
          title: '分期付款',
          route: 'fenqilist',
        },
      ].filter(Boolean)
    }

    navigate(route) {
      if (typeof route === 'string') {
        this.goto(route)
      } else
      if (Array.isArray(route)) {
        this.goto(...route)
      }
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      root.classList.add('main')
      root.classList.add('account')

      const { value: sectionH } = root.query({ as: <div class="cell-1" /> }).next()

      sectionH?.children?.push(
        <div class="contact rightmost">
          <i class="icon icon-contact"></i>
          <span class="title">联系客服</span>
        </div>
      )

      const { value: sectionA } = root.query({ as: <div class="order-item" /> }).next()

      sectionA?.children?.clear?.()
      sectionA?.children?.push(
        ...
        this.orderItems.map(item => (
          <div class="item" onClick={ () => this.navigate(item.route) }>
            <div class={ `cable icon ${ item.icon }` }>
              <van-badge content={ item.badge > 0 ? item.badge : null } max="99" />
            </div>
            <div class="order-type">{ item.title }</div>
          </div>
        ))
      )

      const { value: sectionB } = root.query({ as: <div class="service-item" /> }).next()

      sectionB?.children?.clear?.()
      sectionB?.children?.push(
        ...
        this.serviceItems.map(item => (
          <div class="item" onClick={ () => this.navigate(item.route) }>
            <div class={ `cable icon ${ item.icon }` } />
            <div class="service-type">{ item.title }</div>
          </div>
        ))
      )

      return vnode
    }
  }

  return AccountView
}
