import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { Remote } from '~/services/Remote'

import './withdraw-weixin.css'

@Component()
export
class UserWithdrawToWeixinView extends ReactiveComponent {
  withdrawAmount = 0

  async withdrawToWeixin() {
    try {
      await this.$dialog.confirm({
        title: '确认提现',
        message: `您申请提现的${this.withdrawAmount}元会发放到你的微信零钱，请注意查收哦`,
      })
    } catch (error) {
      return
    }

    this.doWithdrawToWeixin()
  }

  async doWithdrawToWeixin() {
    try {
      await Remote.get().http('/api/check/user_checkout?type=1', {
        method: 'POST',
        body: {
          amount: this.withdrawAmount,
        }
      })
    } catch (error) {
      this.$notify({ type: 'warning', message: error.message })

      return
    }

    this.$notify({ type: 'success', message: '申请成功' })
  }

  render() {
    return (
      <div class="withdraw-weixin">
        <van-cell-group>
          <van-cell class="withdraw-target" title="提现到" value="微信钱包">
            <i class="icon icon-wechat" slot="right-icon" />
          </van-cell>
        </van-cell-group>

        <van-cell-group>
          <van-cell class="withdraw-amount" title="提现金额">
            <van-field type="number" value={ this.withdrawAmount } onInput={ e => (this.withdrawAmount = Number(e)) }></van-field>

            <p slot="extra" class="tips">
              <span class="description">可提现金额￥6280.00</span>
              <span class="shortcut">全部提现</span>
            </p>
          </van-cell>
        </van-cell-group>

        <div class="withdraw-submit">
          <van-button round block type="info" native-type="submit" onClick={ () => this.withdrawToWeixin() }>申请提现</van-button>
        </div>
      </div>
    )
  }
}
