import Vue from 'vue'

export const ActionSheet = Vue.component('van-action-sheet')
export const AddressEdit = Vue.component('van-address-edit')
export const AddressList = Vue.component('van-address-list')
export const Area = Vue.component('van-area')
export const Badge = Vue.component('van-badge')
export const Button = Vue.component('van-button')
export const Calendar = Vue.component('van-calendar')
export const Card = Vue.component('van-card')
export const Cascader = Vue.component('van-cascader')
export const Cell = Vue.component('van-cell')
export const CellGroup = Vue.component('van-cell-group')
export const Checkbox = Vue.component('van-checkbox')
export const CheckboxGroup = Vue.component('van-checkbox-group')
export const Circle = Vue.component('van-circle')
export const Col = Vue.component('van-col')
export const Collapse = Vue.component('van-collapse')
export const CollapseItem = Vue.component('van-collapse-item')
export const ContactCard = Vue.component('van-contact-card')
export const ContactEdit = Vue.component('van-contact-edit')
export const ContactList = Vue.component('van-contact-list')
export const CountDown = Vue.component('van-count-down')
export const Coupon = Vue.component('van-coupon')
export const CouponCell = Vue.component('van-coupon-cell')
export const CouponList = Vue.component('van-coupon-list')
export const DatetimePicker = Vue.component('van-datetime-picker')
export const Divider = Vue.component('van-divider')
export const DropdownItem = Vue.component('van-dropdown-item')
export const DropdownMenu = Vue.component('van-dropdown-menu')
export const Empty = Vue.component('van-empty')
export const Field = Vue.component('van-field')
export const Form = Vue.component('van-form')
export const GoodsAction = Vue.component('van-goods-action')
export const GoodsActionButton = Vue.component('van-goods-action-button')
export const GoodsActionIcon = Vue.component('van-goods-action-icon')
export const Grid = Vue.component('van-grid')
export const GridItem = Vue.component('van-grid-item')
export const Icon = Vue.component('van-icon')
export const Image = Vue.component('van-image')
export const IndexAnchor = Vue.component('van-index-anchor')
export const IndexBar = Vue.component('van-index-bar')
export const Info = Vue.component('van-info')
export const List = Vue.component('van-list')
export const Loading = Vue.component('van-loading')
export const NavBar = Vue.component('van-nav-bar')
export const NoticeBar = Vue.component('van-notice-bar')
export const NumberKeyboard = Vue.component('van-number-keyboard')
export const Overlay = Vue.component('van-overlay')
export const Pagination = Vue.component('van-pagination')
export const Panel = Vue.component('van-panel')
export const PasswordInput = Vue.component('van-password-input')
export const Picker = Vue.component('van-picker')
export const Popover = Vue.component('van-popover')
export const Popup = Vue.component('van-popup')
export const Progress = Vue.component('van-progress')
export const PullRefresh = Vue.component('van-pull-refresh')
export const Radio = Vue.component('van-radio')
export const RadioGroup = Vue.component('van-radio-group')
export const Rate = Vue.component('van-rate')
export const Row = Vue.component('van-row')
export const Search = Vue.component('van-search')
export const ShareSheet = Vue.component('van-share-sheet')
export const Sidebar = Vue.component('van-sidebar')
export const SidebarItem = Vue.component('van-sidebar-item')
export const Skeleton = Vue.component('van-skeleton')
export const Sku = Vue.component('van-sku')
export const Slider = Vue.component('van-slider')
export const Step = Vue.component('van-step')
export const Stepper = Vue.component('van-stepper')
export const Steps = Vue.component('van-steps')
export const Sticky = Vue.component('van-sticky')
export const SubmitBar = Vue.component('van-submit-bar')
export const Swipe = Vue.component('van-swipe')
export const SwipeCell = Vue.component('van-swipe-cell')
export const SwipeItem = Vue.component('van-swipe-item')
export const Switch = Vue.component('van-switch')
export const SwitchCell = Vue.component('van-switch-cell')
export const Tab = Vue.component('van-tab')
export const Tabbar = Vue.component('van-tabbar')
export const TabbarItem = Vue.component('van-tabbar-item')
export const Tabs = Vue.component('van-tabs')
export const Tag = Vue.component('van-tag')
export const TreeSelect = Vue.component('van-tree-select')
export const Uploader = Vue.component('van-uploader')
