import { Component } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '~/services/Remote'

export
function PatrolApplicationViewProvider(BaseComponent) {
  @Component()
  class PatrolApplicationView extends BaseComponent {
    requestingApplication = false
    requestedApplication = false

    /** @override */
    async onSubmit(e) {
      if (this.requestedApplication || this.requestingApplication) {
        return
      }

      this.requestingApplication = true


      try {
        await Remote.get().http(`/api/device/apply_Patrol{?username,mobile,worker}&pid=${this.pid}`, { context: e })
      } catch (err) {
        this.$toast(err.msg ?? String(err))

        return
      } finally {
        this.requestingApplication = false
      }

      this.$toast('提交成功,管理员审核后方可使用')

      this.requestedApplication = true
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: submitButtonView } = root.query({ className: n => /\bsubmit\b/.test(n ?? '') }).next()
      // const { value: submitButtonView } = root.query({ as: <van-button /> }).next()

      submitButtonView?.after(
        <van-button class="submit" native-type="submit" type="info" size="large" block disabled={ this.requestedApplication } loading={ this.requestingApplication } loadingText="正在提交...">提交</van-button>
      )
      submitButtonView?.remove()

      return vnode
    }
  }

  return PatrolApplicationView
}
