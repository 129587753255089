import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component } from '~/commons/component/decorators'

import { Remote } from '~/services/Remote'

import { router } from '~/app/router'

import { Col, Row } from 'vant'

import './bind.css'

@Component()
export
class RevenueBindView extends ReactiveComponent {

  userFormData = { name:null, mobile:null, code:null, store_id : router.currentRoute.query.store_id }

  codeLoading = false //验证码按钮 加载效果
  codeTime = 0 //验证码等待时间

  btnDisabled = false //绑定到后台按钮禁用效果

 async submitForm(){
  // 校验验证码
  try {
    await Remote.get().http('/api/sms/check_code',{
      method:'POST',
      body:{
        mobile:this.userFormData.mobile,
        code:this.userFormData.code,
        type:1
      }
    })
  }catch(error){
    this.$notify({ type: 'warning', message: error.message })

    return
  }

  // 提交逻辑
  let res
   try {
    res = await Remote.get().http('/api/revenue/userSubmit',
    {
      method:'POST',
      body: {
        ...this.userFormData
      }
    })

   } catch(error){
    this.$notify({ type: 'warning', message: error.message })

    return
   }

   this.$notify({ type: 'success', message: res??'申请成功' })

   this.btnDisabled = true

   const btn = document.getElementsByClassName('newSubmit')
   btn[0].classList.add('disabledBtn')
  }

  async sendCodeBtn(){
    this.codeLoading = true
    // 验证码等待时间结束后 才进行发送验证码逻辑
    if(this.codeTime > 0) return

    // 发送验证码
    let res
     try {
      res = await Remote.get().http('/api/sms/send_sms',{
        method:'POST',
        body: {
          mobile: this.userFormData.mobile,
          type: 1,
        }
      })
     }catch(error){
      this.$notify({ type: 'warning', message: error.message })

      this.codeLoading = false
      return
     }

    // 定时器禁止连续获取验证码
    // 初始化60s
    this.codeTime = 60
    // 设置定时器id
    let timeId
    timeId = window.setInterval(() => {
      this.codeTime--
      // 判断如果codeTime<=0,清理定时器
      if(this.codeTime <=0){
        window.clearInterval(timeId)
      }
    },1000)

    this.codeLoading = false
    this.$toast.success('已发送');
  }

  // 防止内存泄漏，每当组件卸载，清理定时器
  beforeDestroy(){
    window.clearInterval(timeId)
  }

 render(){
  return (
  <section>
    <div class="wrapNewLogin">
    <div class="newLogo">
        <i class='logo'/>
      </div>
      <div class="newLogoBar">
        <p>
          <i class='user'/>
          <Input type="text" placeholder="请输入分成人名称"  value={ this.userFormData.name } onInput={ (e) => this.userFormData.name = e.target.value }/>
        </p>
        <p>
          <i class='mobile'/>
          <Input type="text" placeholder="请输入联系电话"   value={ this.userFormData.mobile } onInput={ (e) => this.userFormData.mobile = e.target.value }/>
        </p>
        <p>
          <i class='code'/>
           <Row class='code-container'>
            <Col span={ 14 }>
            <Input
            type="text"
            placeholder="请输入验证码"
            value={ this.userFormData.code }
            onInput={ (e) => this.userFormData.code = e.target.value }
            style="top: 1px;left: 29px;"
            />
            </Col>
            <Col span={ 10 }>
            <van-button
              type="info"
              size="small"
              loading={this.codeLoading}
              loading-text="发送中..."
              onClick={() => this.sendCodeBtn()}
              disabled={this.codeTime > 0 ? true : false}
              round
              >{this.codeTime > 0 ? `等待${this.codeTime}秒`: '发送验证码'}</van-button>
            </Col>
           </Row>
        </p>
      </div>
      <div class="newLogoBtn">
        <button class="newSubmit" onClick={ () => this.submitForm() } disabled={this.btnDisabled}>绑定到后台</button>
      </div>
    </div>
  </section>
  )
 }
}
