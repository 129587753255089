import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { router } from '~/app/router'

import classNames from 'classnames';

import { Remote } from '~/services/Remote'

import * as echarts from 'echarts/core';
import {
  TitleComponent,
  GridComponent,
  DataZoomComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  BarChart,
  CanvasRenderer
]);


import './DeviceBinding.css'

export
  function DeviceBindingViewProvider(BaseComponent) {
  @Component()
  class DeviceBindingView extends BaseComponent {
    router = router
    unlock = this.router.currentRoute.query.unlock
    activeItem = 0
    tabList = [
      { name: '近1周', report_type: 'day', gap: 7 },
      { name: '近1月', report_type: 'day', gap: 30 },
      { name: '近6月', report_type: 'month', gap: 6 },
      { name: '近1年', report_type: 'month', gap: 12 }]


    @Watch('activeItem', { immediate: true })
    async loadDrawBar() {
      const { list } = await Remote.get().http('/api/device/device_usage_report', {
        method: 'POST',
        body: {
          device_id: +this.router.currentRoute.query.id,
          report_type: this.tabList[this.activeItem].report_type,
          gap: this.tabList[this.activeItem].gap
        }
      })
      this.drawBar(list.dates, list.hours)
    }

    // echarts柱状图配置
    drawBar(dates, hours) {
      const myChart = echarts.init(document.getElementsByClassName('barChart')[0])
      const dataAxis = dates
      let data = hours
      // 数据窗口范围的结束百分比 - 控制显示7条柱状
      function endValue() {
        if (data.length > 12) {
          return (6 / data.length) * 100
        } else {
          return null
        }
      }
      // 控制柱形宽度
      function barWidth() {
        if (dates.length == 12) {
          return 10
        } else {
          return 15
        }
      }
      myChart.setOption(
        {
          title: {
            text: '使用时长/小时',
            left: '8%',
            textStyle: {
              color: '#70798C',
              fontSize: 15
            }
          },
          xAxis: {
            data: dataAxis,
            axisLabel: {
              color: '#000',
              fontSize: 10,
              formatter: function (value, index) {
                return value.substring(4);
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            z: 0
          },
          yAxis: {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            }
          },
          // 用于区域缩放
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: endValue()
            }
          ],
          series: [
            {
              type: 'bar',
              data: data,
              barWidth: barWidth(),
              itemStyle: {
                borderRadius: [7, 7, 7, 7],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#83bff6' },
                  { offset: 0.5, color: '#188df0' },
                  { offset: 1, color: '#188df0' }
                ]),
              },
              label: {
                show: true,
                position: 'top',
                fontSize: 10
              },
            }
          ]
        })
    }


    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      // const { value: titleView } = root.query({ as: <span class="health-plan-title" /> }).next()

      // titleView?.children.clear()
      // titleView?.children.push(<span>健康计划</span>)


        const {value:deviceTitleView}=root.query({ as: <div class="device-card-title" slot="title" /> }).next()

        deviceTitleView?.children.clear()
        deviceTitleView?.children.push(<span>{this.deviceInfo.model}{this.deviceInfo.model_name}</span>)


        const { value: titleView } = root.query({ as: <span class="health-plan-title" /> }).next()

        titleView?.children.clear()
        titleView?.children.push(<span>使用时长</span>)

        const { value: activateView } = root.query({ as: <div class="is-activate" /> }).next()
        activateView?.remove()

        const { value: descriptionView } = root.query({ as: <div class="device-card-description" slot="desc" /> }).next()

        descriptionView?.children.clear()
        descriptionView?.children.push(
          <div>设备号：{this.deviceInfo.device_code }</div>,
          <div >是否在线：<span class={classNames(this.deviceInfo.online?'good':'bad')}>{this.deviceInfo.online ? '在线':'离线'}</span></div>,
          <div>设备状态：<span class={classNames(this.deviceInfo.status?'bad':'good')}>{this.deviceInfo.status?'设备故障':'设备正常'}</span></div>
        )


        const { value: contectView } = root.query({ as: <van-form class="health-plan-form" /> }).next()

        contectView?.after(
          <div class='tab'>{
            this.tabList.map((item, index) => {
              return <div class={classNames('tabItem', this.activeItem === index ? 'active' : '')} onClick={() => this.activeItem = index}>{item.name}</div>
            })
          }</div>
          ,
          <div class='barChart'></div>
        )
        contectView?.remove()



      return vnode
    }
  }

  return DeviceBindingView
}

