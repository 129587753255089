import { createVNodeProxy } from "@rc/vue-vdom";
import { Component } from '~/commons/component/decorators'

export
 function DeviceDetectionViewProvider(BaseComponent){
  @Component()
  class DeviceDetectionView extends BaseComponent {
    sliderValue = 0

    sliderChange(value){
      console.log(1);
      console.log('滑动了，处理过的value',Math.round(value / 10));
    }

    render(){
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const {value:voiceControlView} = root.query({ as:  <div class="voice-control"  /> }).next()

      voiceControlView?.after(
        <div style="margin: 2.5rem 1.5rem 1.6rem 1.5rem;">
          <van-slider  value={ this.sliderValue } onInput={ e => this.sliderValue = e } step="10" onChange={ e => this.sliderChange(e) } />
        </div>
      )

      return vnode
    }
  }

  return DeviceDetectionView
 }
