import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '~/services/Remote'

import classNames from 'classnames'

import './income.css'

export
function UserWithdrawRecordViewProvider(BaseComponent) {
  @Component()
  class UserWithdrawRecordView extends BaseComponent {
    page = 0
    pageSize = 10

    onLoad() {
      if (this.refreshing) {
        this.page = 0
        this.list = []
        this.refreshing = false
      } else {
        this.page += 1
      }

      this.loadWithdrawRecords()
    }

    async loadWithdrawRecords() {
      let data

      try {
        data = await Remote.get().http('/api/check/user_check_record{?page}', { context: this, method: 'POST' })
      } catch (error) {
        this.$notify({ type: 'warning', message: error.message })

        return
      } finally {
        this.loading = false
      }

      if (data.length < this.pageSize) {
        this.finished = true
      }

      for (const item of data) {
        this.list.push(item)
      }
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: listView } = root.query({ as: <van-list /> }).next()

      listView?.children?.clear?.()
      listView?.children?.push(
        ...
        this.list.map(item => (
          <div class="item">
            <div class="top">
              <div class="left">提现 微信</div>
              <div class="right">{ Number(item.check_money).toFixed(2) }</div>
            </div>
            <div class="bottom">
              <div class="left">{ item.check_time }</div>
              <div class={ classNames('right', item.status > 0 ? 'blue' : 'red' )}>
                { item.status > 0 ? '已提取' : '提取中' }
              </div>
            </div>
            <van-divider />
          </div>
        ))
      )

      return vnode
    }
  }

  return UserWithdrawRecordView
}
