import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { Remote } from '~/services/Remote'

import { List } from "vant";
import { DatePicker } from 'element-ui'

import './device-revenue-list.css'


@Component()
export
  class DeviceRevenueListView extends ReactiveComponent {

  store_related = JSON.parse(localStorage.getItem('store_related')) || {}

  date = this.store_related.date || this.defaultDate() //时间选择器 读本地缓存里store_related  1.有：读 store_related.date 2.无：读 默认选择当前时间
  showTimes = false //时间选择器日历 显示/隐藏
//   setDateRange = {
//     disabledDate: time => {
//         // 禁用今天之后的日期【当前天可选】
//         return time.getTime() > Date.now();
//   }
// }

  queryPageNum = 1 //当前页码
  queryPageSize = 20 //每页行数


  revenueOrderList = [] //列表数据
  total   //总行数
  loading = false //列表总加载效果
  listLoading = false // 列表数据上拉更新加载效果
  finished = false //列表数据上拉更新 提示 没有更多了 显示/隐藏


  created() {
    this.editPageTitle()

    this.getRevenueOrderListData()
  }

  @Watch('date')
  loadRevenuedOrderListData() {
    // date改变 刷新页面数据 而不是加载下一页
    // 所以queryPageNum queryPageSize 需要读初始值 再调用获取列表数据接口
    this.queryPageNum = 1
    this.queryPageSize = 20
    this.getRevenueOrderListData()
  }

  // 解决document.title失效问题
  editPageTitle() {
    const documentTitle = this.store_related.store_name || '门店分成'
    document.title = documentTitle;
    let iframe = document.createElement('iframe');
    //  iframe.src = require('/favicon.ico');
    iframe.style.display = 'none';
    // 这个就是我们异步获取 title 的函数
    let fn = function () {
      setTimeout(function () {
        iframe.removeEventListener('load', fn);
        document.body.removeChild(iframe);
      }, 0);
    };

    iframe.addEventListener('load', fn);
    document.body.appendChild(iframe);
  }

  // 当前时间格式化 yy-mm-dd
  defaultDate() {
    const currentDate = new Date();

    // 获取年份、月份和日期
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    // 格式化为 yy-mm-dd 格式
    return formattedDate = `${year}-${month}-${day}`;
  }

  //获取列表数据
  async getRevenueOrderListData() {
    this.loading = true
    let res
    try {
      res = await Remote.get().http('/api/revenue/order', {
        method: 'POST',
        body: {
          date: this.date,
          store_id: this.store_related.store_id,
          page: this.queryPageNum,
          page_size: this.queryPageSize
        }
      })
    } catch (error) {
      this.$notify({ type: 'warning', message: error.message })

      return
    }

    // 判断是否刷新页面数据还是加载下一页数据
    // queryPageNum = 1 ： 刷新页面数据 这里主要为时间选择器服务
    if (this.queryPageNum === 1) {
      this.revenueOrderList = res.list
    } else {
      //  queryPageNum != 1 ： 加载下一页 这里是为了触发van-list onLoad事件
      this.revenueOrderList = [...this.revenueOrderList, ...res.list]
    }

    this.total = res.total
    this.loading = false
  }

  // 日期选择后触发Confirm
  onConfirm(date) {
    this.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    this.showTimes = false
  }

  loadRevenueListData() {
    if (this.revenueOrderList.length === this.total) {
      this.finished = true
    } else {
      this.queryPageNum++
      this.getRevenueOrderListData() //加载下一页
    }
    this.listLoading = false
  }

  render() {
    return (
      <div class='device-revenue-list'>
        <div class='seach-time'>
          <van-cell title="扫码收益时间" value={this.date} onClick={() => this.showTimes = true} />
          <van-calendar
            value={this.showTimes}
            onInput={() => this.showTimes = false}
            onConfirm={(date) => this.onConfirm(date)}
            min-date={new Date(2022, 1, 1)}
            max-date={new Date()}
            default-date={new Date(this.date)}
          />
          {/* <span>扫码收益：</span>
          <DatePicker
            value={this.date}
            onInput= { (e) => this.date = e}
            type="date"
            size="small"
            value-format="yyyy-MM-dd"
            picker-options={this.setDateRange}
            placeholder="选择日期"
            editable = { false }
            clearable = { false }
            style="width: 141px;">
          </DatePicker> */}
        </div>

        <div class='content' v-loading={this.loading}>
          <ul class='list-box'>
            <li style="font-weight: 600">
              <span class='w60'>设备/订单号</span>
              <span class='w20'>订单金额</span>
              <span class='w20'>分成</span>
            </li>
            <div class='order-box'>
              {
                this.revenueOrderList.length === 0 &&
                <van-empty description="暂无数据" />
              }
              {
                this.revenueOrderList.length != 0 && (
                  <List
                    loading={this.listLoading}
                    onInput={e => (this.listLoading = e)}
                    finished={this.finished}
                    loading-text="努力加载中"
                    finished-text="没有更多了"
                    offset={300}
                    onLoad={() => this.loadRevenueListData()}
                  >
                    {
                      this.revenueOrderList.map((item, index) => (
                        <li>
                          <span class='w60'>
                            <p>{item.device_name}</p>
                            <p class='fs13'>{item.order_no}</p>
                          </span>
                          <span class='w20'>{item.pay_amount}</span>
                          <span class='w20'>{item.revenue_amount}</span>
                        </li>
                      ))
                    }

                  </List>
                )
              }

            </div>
          </ul>
        </div>
      </div>
    )
  }
}
